import axios from "axios";

const sessionList = async ()=> {
  try {
    
    const response = await axios.get(`${process.env.REACT_APP_API}/api/sessions`);
    
    return response;
  } catch (e) {
    throw new Error('An error occurred while listing sessions');
  }
}

const sessionCreate = async (moduleId)=> {
  try {

    const data = {
      moduleId
    }
    
    const response = await axios.post(`${process.env.REACT_APP_API}/api/sessions`,data);

      if (response?.data) {
        response.data = {...data, ...response.data};
      }
      
      return response;
  } catch (e) {
    throw new Error('An error occurred while creating session');
  }
}

export { sessionList, sessionCreate }
