import axios from "axios";
import { isTokenExpired } from './auth';

let isProcess = false;

//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  async (request) => {

    const tokenCheck = getLocal('auth_token');
    const ccode = getLocal('ccode');
    if (tokenCheck) {
      const validToken = isTokenExpired(tokenCheck);
      if (ccode && validToken === false && !isProcess) {
        // Generate new token
        isProcess = true;
        await getToken(true);
        isProcess = false;
      }
    }

    const token = getLocal("auth_token");
    if (token) request.headers.authorization = `Bearer ${token}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         console.log('ATTEMPTING RESCUE OF ORIGINAL REQUEST');
//         await getToken(true);
//         return axios(originalRequest);
//       } catch (e) {
//         return Promise.reject(e);
//       }
//     }
//     return Promise.reject(error);
//   }
// );


const setSess = (key, val) => {
  sessionStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const getSess = (key) => {
  const v = sessionStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const setLocal = (key, val) => {
  localStorage.setItem(key, val ? JSON.stringify(val) : false);
}

const setLocalAsync = (key, value) => {
  return new Promise((resolve) => {
    localStorage.setItem(key, value ? JSON.stringify(value) : false);
    resolve(true);
  });
};

const getLocal = (key) => {
  const v = localStorage.getItem(key);
  return v ? JSON.parse(v) : false;
}

const emptyLocal = () => {
  localStorage.clear();
}

let tokenStatusTimer;
const getToken = async (fresh) => {
  const currentToken = getLocal("auth_token");
  const getTokenStatus = getSess("getToken_status");

  if (getTokenStatus) {
    if (tokenStatusTimer) {
      clearInterval(tokenStatusTimer);
    }
    tokenStatusTimer = setTimeout(() => {
      setSess("getToken_status", '');
    },2000);
    return getTokenStatus;
  }

  if ((!currentToken || fresh) && !getTokenStatus) {
    setSess("getToken_status",'wait');

    tokenStatusTimer = setTimeout(() => {
      setSess("getToken_status", '');
    },2000);

    const accessCode = getLocal('ccode');

    const body = {
      code: accessCode
    }

    try {
      let tokenRes = await axios.post(`${process.env.REACT_APP_API}/api/token`, body);

      console.log(tokenRes);

      if (tokenRes.data && tokenRes.status === 200) {
        setLocal("auth_token", tokenRes.data.token);
      }

      return tokenRes;
    } catch (e) {
      console.log(e);
      if(e.response.status === 401) {
        console.log('unauthorized, logging out');
        localStorage.clear();
        window.location.assign("/");
      }
      else {
        setSess("getToken_status", '');
        throw new Error(e);
      }
    }
  }

  return {data: currentToken, status: 200};
};

export {
  getSess,
  getLocal,
  setSess,
  setLocal,
  setLocalAsync,
  getToken,
  emptyLocal
};
