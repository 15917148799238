import React, { useState } from "react";
import { getToken, getLocal, setLocal } from "../utils/dataFetching";

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

const AuthProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(getLocal("auth_token") && getLocal("ccode"));
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const login = async (ccode) => {
    try {
      setLocal('ccode', ccode );
      setIsAuthenticating(true);

      let token = await getToken(ccode);

      setIsAuthenticating(false);

      if (token === 'wait') {
        return token;
      }

      if (token) {
        setIsAuthenticated(true);

        return { ...token };
      }
      else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      setIsAuthenticating(false);
      return { status: "error", error: error?.message ? error.message : "An unexpected error occurred, please try again."};
    }
  };

  const logout = async () => {
    console.log("logout");
    try {
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      localStorage.clear();
      window.location.assign("/");
    } catch (error) {
      return { status: "error", error: error.message };
    }
  };



  const authProps = {
    isAuthenticated,
    isAuthenticating,
    setAuthStatus: setIsAuthenticated,
    login,
    logout
  };

  return <AuthContext.Provider value={authProps} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`);
  }
  return context;
}

export { AuthContext, AuthProvider, useAuth };
