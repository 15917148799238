
exports.wakeWords = ["hey transit"];
exports.wakeWordDisplay = "Hey Transit";

exports.stopRecordingDelay = 1500; // 1.5 seconds

// Prompt Tester
// exports.promptTesterModId = "664a8bb685f669fb97714e53";

// KB
// exports.kbModList = {
//    'cybertruck': '6642267adf09872383c6a7f0',
//    'ford-150': '664a870e85f669fb97714dea',
//    'ford-mach-e': '664a879585f669fb97714df5',
//    'gmc-hummer': '664a87de85f669fb97714dfe',
//    'hyundai-ioniq': '664a88a485f669fb97714e07',
//    'kia-ev6': '664a891685f669fb97714e10',
//    'lucid-air': '664a894a85f669fb97714e17',
//    'mercedes-450': '664a898285f669fb97714e1e',
//    'polestar2': '664a89b885f669fb97714e25',
//    'rivian-r1': '664a89e485f669fb97714e2c'
// };

// Role Play
// exports.rpModList = {
//    'role-play': '66423afbdf09872383c6a801',
//    'objection': '664a8aad85f669fb97714e3d',
//    'objection-advance': '664a8ade85f669fb97714e42'
// };

// KB
// exports.kbVechList = {
//    'cybertruck': {brand: 'Tesla', model: '2024 DUAL MOTOR CYBERTRUCK'},
//    'ford-150': {brand: 'Ford', model: 'F150 Lightning'},
//    'ford-mach-e': {brand: 'Ford', model: 'Mustang Mach-E'},
//    'gmc-hummer': {brand: 'GMC', model: 'Hummer EV'},
//    'hyundai-ioniq': {brand: 'Hyundai', model: 'IONIQ 6'},
//    'kia-ev6': {brand: 'Kia', model: 'EV6'},
//    'lucid-air': {brand: 'Lucid', model: 'Air'},
//    'mercedes-450': {brand: 'Mercedes', model: 'EQS 450+'},
//    'polestar2': {brand: 'Polestar', model: 'Polestar2'},
//    'rivian-r1': {brand: 'Rivian', model: 'R1S'},
// };

// Landing pages
// exports.needsLink = `/role-play`;
// exports.objectionLink = `/objection`;
// exports.advanceLink = `/objection-advance`;