import axios from "axios";
import showdown from "showdown";

/**
 * Return trip plan based on desired destination
 */
const getTripPlan = async (tripQuery, lat, lon, sessionId, logout) => {
    console.log(`${tripQuery} ${lat} ${lon}`);
    try {
        console.log("in getTripPlan");


        // If the trip query is just a location ("nearest starbucks") we need to prefix "plan a trip to " to the query so we can receive predictable results
        let formattedQuery;

        // if it doesn't include the phrasing, add it
        if(!tripQuery.toLowerCase().includes('plan a trip to')) {
            console.log("formatting query with prefix");
            formattedQuery =  `plan a trip to ${tripQuery}`;
        } else {
            console.log("query is formatted correctly");
            formattedQuery = tripQuery;
        }

        const data = { 
            tripQuery: formattedQuery,
            lat,
            lon,
            sessionId
        };

        console.log('data to LLM:');
        console.log(data);

        const response = await axios.post(`${process.env.REACT_APP_API}/api/llm`, data);
        const { messages } = response.data;
        console.log(response.data.response.choices[0].message.content);

        const converter = new showdown.Converter();
        const htmlContent = converter.makeHtml(response.data.response.choices[0].message.content);
        
        return { messages, htmlContent };

    } catch (e) {
        console.log(e);
        if(e.response.status === 401) logout();
        throw new Error(`An error occurred while getting LLM Response: ${e.message}`);
    }
}

export {
    getTripPlan
}