// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnterCode-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .EnterCode-Container input {
    border: none;
    border-bottom: 2px solid black;
    width: 500px;
    padding-left: 10px;
    color: green;
    text-align: center; }
    .EnterCode-Container input:focus {
      outline: none; }
    .EnterCode-Container input::placeholder {
      text-align: center;
      color: red; }
`, "",{"version":3,"sources":["webpack://./src/pages/style/EnterCode.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB,EAAA;EAJ3B;IAWQ,YAAY;IACZ,8BAA8B;IAC9B,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB,EAAA;IAhB1B;MAoBY,aAAa,EAAA;IApBzB;MAwBY,kBAAkB;MAClB,UAAU,EAAA","sourcesContent":[".EnterCode-Container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n\n    p {\n        // text-align: center;\n    }\n\n    input {\n        border: none;\n        border-bottom: 2px solid black;\n        width: 500px;\n        padding-left: 10px;\n        color: green;\n        text-align: center;\n\n\n        &:focus {\n            outline: none;\n        }\n\n        &::placeholder {\n            text-align: center;\n            color: red;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
