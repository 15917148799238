import { useState, useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import {
  APIProvider
} from '@vis.gl/react-google-maps';
import { BsKeyboard } from "react-icons/bs";
import ControlledMap from '../components/map/ControlledMap';
import AudioControl from '../components/general/AudioControl';
import { getTripPlan } from '../utils/fetch/tripPlanning';
import { useAuth } from '../context/AuthContext';
import { getLocal, setLocal} from '../utils/dataFetching';
import { sessionCreate, sessionList } from '../utils/session/session';
import './style/Transit.scss';

const Transit = () => {
  // Auth
  const auth = useAuth();
  const { logout } = auth; 

  // Session
  const [sessionId, setSessionId] = useState(getLocal('session_id'));
  // state for current location
  const [currentLocation, setCurrentLocation] = useState(null);
  // state for destination
  const [destination, setDestination] = useState(null);
  // trip query controlled input
  const [tripPlan, setTripPlan] = useState('');
  // returned data for display / visualization
  const [displayedTripHeader, setDisplayedTripHeader] = useState("Where would you like to go?");
  // leg geometry for lines
  const [geometryLines, setGeometryLines] = useState([]);

  const [audioState, setAudioState] = useState('ready');
  // keep for now
  const [isTalking, setIsTalking] = useState(false);

  // State for if api call is still processing or not
  const [processing, setProcessing] = useState(false);
  const [inputActive, setInputActive] = useState(false);
  // const audioRef = useRef(null);
  const inputRef = useRef(null);
  const keyboardRef = useRef(null);

  
  const getSession = useCallback(async () => {
    console.log("getSession");

    try {
      if (!sessionId) {

        // Check if session exists in DB
        const sessionResList = await sessionList();
        console.log(sessionResList);
        // We're only using the first session
        if(sessionResList.data.sessions[0]) {
          setSessionId(sessionResList.data.sessions[0]._id);
        } 

        // If empty, create one
        if (!sessionId) {
          console.log("getSession::Create new");

          const sessionRes = await sessionCreate();
          if (sessionRes.data?.session) {
            setSessionId(sessionRes.data.session._id);
            setLocal('session_id', sessionRes.data.session._id);
          }
        }
      }
    } catch (e) {

      console.error('Error fetching session:', e);
      logout();
    }

  }, [sessionId, logout]); 

  const setDestinationCoords = (lat, lng) => {
    setDestination({key: 'Destination', location: { lat, lng }, colors: { bg: '#0F52BA', glyph: '#FFFFFF', border: '#66000000'  } });
  }

  const toggleInputActive = (bool) => {
    if(bool) setInputActive(bool);
    else setInputActive(!inputActive);
  }

  const onInputChange = e => {
    setTripPlan(e.target.value);
  }

  const extractRouteGeometry = async (legs) => {
    const polylines = [];

    for(const leg of legs) {
      polylines.push(leg.legGeometry.points);
    }

    return polylines;
  }

  const requestTripPlan = async () => {
    try {
      console.log(tripPlan);
      // console.log(data);
      console.log(currentLocation);
      setDisplayedTripHeader("Creating trip plan...");
      // setDisplayedTripItinerary([]);
      setProcessing(true);

      const requestedTripPlan = await getTripPlan(tripPlan, currentLocation.location.lat, currentLocation.location.lng, sessionId, logout);
      console.log("requestedTripPlan:");
      console.log(requestedTripPlan);

      const parser = new DOMParser();
      setDisplayedTripHeader("");
      document.querySelector(".itinerary").replaceChildren(parser.parseFromString(requestedTripPlan.htmlContent, "text/html").documentElement);

      let toolRoleMsgs = requestedTripPlan.messages.filter(msg => msg.role === 'tool');
      let tripMapData = null;

      for(const toolMsg of toolRoleMsgs) {
        console.log("toolMsg being checked: ");
        console.log(toolMsg)
        try {
          tripMapData = JSON.parse(toolMsg.content);
          console.log("parsed JSON trip plan: ");
          console.log(tripMapData);
        } catch (e) {
          console.log("unable to parse JSON trip plan: ");
          console.log(e);
        }
      }

      if(tripMapData !== null && tripMapData.plan.itineraries.length > 0) {
        const { lat, lon } = tripMapData.plan.to;
          setDestinationCoords(lat, lon);
          setGeometryLines(await extractRouteGeometry(tripMapData.plan.itineraries[0].legs));
      }

      //clear trip plan query input
      setTripPlan('');
      setInputActive(false);
      setProcessing(false);
    } catch (e) {
      console.log(e);
      setProcessing(false);
    }
  }

  const handleAudioStateChange = useCallback(async (state, transRes) => {
    console.log("in handleAudioStateChange - Transit.js");

    if (state === 'ready') {
      console.log("state === ready");
      //send human audio transcription to LLM
      if (transRes && transRes.transcription) {
        setDisplayedTripHeader("Creating trip plan...");
        setProcessing(true);
        // we don't have access to currentLocation within useCallback, and setting it as a dependency doesn't seem to fix the problem
        // TODO: find a way to use the geolocation acquired when the page first loaded - this is a temporary workaround
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;

          console.log("transcription present:");
          console.log(transRes.transcription);

          const session_id = getLocal('session_id');
  
          const requestedTripPlan = await getTripPlan(transRes.transcription, latitude, longitude, session_id, logout);
          console.log('requestedTripPlan:');
          console.log(requestedTripPlan);

          const parser = new DOMParser();
          setDisplayedTripHeader("");
          document.querySelector(".itinerary").replaceChildren(parser.parseFromString(requestedTripPlan.htmlContent, "text/html").documentElement);

          let toolRoleMsgs = requestedTripPlan.messages.filter(msg => msg.role === 'tool');
          let tripMapData = null;

          for(const toolMsg of toolRoleMsgs) {

            try {
              tripMapData = JSON.parse(toolMsg.content);
              console.log("parsed JSON trip plan: ");
              console.log(tripMapData);
            } catch (e) {
              console.log("unable to parse JSON trip plan: ");
              console.log(e);
            }
          }

          if(tripMapData !== null && tripMapData.plan.itineraries.length > 0) {
            const { lat, lon } = tripMapData.plan.to;
              setDestinationCoords(lat, lon);
              setGeometryLines(await extractRouteGeometry(tripMapData.plan.itineraries[0].legs));
          }

          setTripPlan('');
          setInputActive(false);
          setProcessing(false);
          setAudioState(state);

        }, geoLocateError, { enableHighAccuracy: true });
      }
    } else {
      console.log(state);
      setIsTalking(false);
      setAudioState(state);
      setProcessing(false);
    }

  }, [logout]);


  function geoLocateSuccess(position) {
    const { latitude, longitude } = position.coords;
    setCurrentLocation({key: 'Current Location', location: { lat: latitude, lng: longitude  }, colors: { bg: '#FF7F50', glyph: '#FFFFFF', border: '#228B2200' }});
  }

  function geoLocateError(e) {
    console.log(`Error: ${e.message}`);
    setProcessing(false);
  }

  // get user location
  useLayoutEffect(() => {
    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(geoLocateSuccess, geoLocateError, { enableHighAccuracy: true });
    }

  }, []);

  // focus on shown input
  useEffect(() => {
    inputRef.current?.focus();
  }, [inputActive]);

  // Get the session
  useEffect(() => {
    console.log("useEffect::getSession")
    getSession();
  }, [getSession]);

  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} onLoad={() => console.log('Maps API has loaded.')}>

      <div className="transit-container">
        <section className="header">
          <h3>Trip Planner AI</h3>
          {/* logout button for auth testing */}
          {/* <button onClick={logout} style={{ position: 'absolute', top: 0, right: 0}}>Logout</button> */}
        </section>

        <section className="map">
            <div className='map-container'>
              {/* Only render the map if we were able to get geolocation from browser */}
              {currentLocation && 

                <ControlledMap
                  currentLocation={currentLocation}
                  locations={[currentLocation, destination].filter(value => value != null)}
                  polygons={geometryLines}
                />}

            </div>
          </section>

          <section className="transcript">
            <div className='transcript-content'>
              <h4>{displayedTripHeader}</h4>

              {inputActive && <input type='text' disabled={processing} ref={inputRef} onChange={onInputChange} value={tripPlan} onKeyDown={(e) => { if (e.key === "Enter") requestTripPlan(); }} /> }

              <div className="itinerary">
              </div>
              
            </div>
          </section>

          <section className='controls'>
                <div className="bumper"></div>
                <AudioControl isTalking={isTalking} audioState={audioState} onStateChange={handleAudioStateChange} />
                <button className="keyboard" onClick={() => toggleInputActive()} ref={keyboardRef}><BsKeyboard color='white' size={40} /></button>
          </section>

      </div>

    </APIProvider>

  );
};
  
export default Transit;