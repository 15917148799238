import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

const brand = process.env.REACT_APP_BRAND

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


if (brand) {
  document.documentElement.style.setProperty('--default-bg-image', `url('../assets/${brand}/images/bg/default.png')`);
  document.documentElement.style.setProperty('--landscape-bg-image', `url('../assets/${brand}/images/bg/landscape.png')`);
  document.documentElement.style.setProperty('--portrait-bg-image', `url('../assets/${brand}/images/bg/portrait.png')`);

  document.documentElement.style.setProperty('--header-logo', `url('../assets/${brand}/images/logo.png')`);

  document.documentElement.style.setProperty('--default-audio-control-bg-image', `url('../assets/${brand}/images/audio/ready.png')`);
  document.documentElement.style.setProperty('--recording-audio-control-bg-image', `url('../assets/${brand}/images/audio/ready.png')`);
  document.documentElement.style.setProperty('--processing-audio-control-bg-image', `url('../assets/${brand}/images/audio/processing.gif')`);
  document.documentElement.style.setProperty('--listening-audio-control-bg-image', `url('../assets/${brand}/images/audio/listening.gif')`);

  document.documentElement.style.setProperty('--transcript-switch-mode-bg-image', `url('../assets/${brand}/images/avatar.png')`);
  document.documentElement.style.setProperty('--avatar-switch-mode-bg-image', `url('../assets/${brand}/images/transcript.png')`);

  document.documentElement.style.setProperty('--processing-role-play-overlay-bg-image', `url('../assets/${brand}/images/avatar/processing.gif')`);
  document.documentElement.style.setProperty('--recording-role-play-overlay-bg-image', `url('../assets/${brand}/images/avatar/recording.gif')`);

  document.documentElement.style.setProperty('--call-button-bg-image', `url('../assets/${brand}/images/call.png')`);
  document.documentElement.style.setProperty('--end-button-bg-image', `url('../assets/${brand}/images/end.png')`);
  document.documentElement.style.setProperty('--talk-button-bg-image', `url('../assets/${brand}/images/talk.png')`);
  document.documentElement.style.setProperty('--talking-button-bg-image', `url('../assets/${brand}/images/talking.gif')`);
  document.documentElement.style.setProperty('--wait-button-bg-image', `url('../assets/${brand}/images/wait.png')`);

  document.documentElement.style.setProperty('--end-modal-bg-image', `url('../assets/${brand}/images/end-modal.png')`);

  document.documentElement.style.setProperty('--knowledge-base-hero-bg-image', `url('../assets/${brand}/images/cybertruck.png')`);
  document.documentElement.style.setProperty('--knowledge-base-iphone-bg-image', `url('../assets/${brand}/images/bg/assistant-iphone.png')`);
  document.documentElement.style.setProperty('--knowledge-base-landscape-bg-image', `url('../assets/${brand}/images/bg/assistant-landscape.png')`);
  document.documentElement.style.setProperty('--knowledge-base-portrait-bg-image', `url('../assets/${brand}/images/bg/assistant-portrait.png')`);
}
