import { useState, useCallback, useRef, useEffect, useLayoutEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './style/EnterCode.scss';


const EnterCode = () => {
  const { login } = useAuth();

  const [code, setCode] = useState('');

  const onInputChange = e => {
    setCode(e.target.value);
  }


  const attemptLogin = async () => {
    try {
      console.log("EnterCode::attemptLogin::START");

      let loginResult = await login(code);
      console.log(loginResult);

      if (loginResult === 'wait') {
        return;
      }
      if (loginResult.status === 200) {
        console.log("EnterCode::attemptLogin::200");
        window.location = "/";
      } else if (loginResult.status === 'error') {
        console.log('Invalid key');
      } else {
        console.log(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <div className="EnterCode-Container">
      <p>Welcome to Trip Planner AI. Authenticate to continue.</p>
      <input type='text' value={code} placeholder='Enter Access Code...' onChange={onInputChange} onKeyDown={(e) => { if (e.key === "Enter") attemptLogin(); }} autoFocus/> 
    </div>
  );
};

export default EnterCode;