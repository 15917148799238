import { useCallback } from 'react';

import {
    Map,
    useMap
  } from '@vis.gl/react-google-maps';
import MapMarkers from './MapMarkers';
//@ts-ignore
import { Polyline } from './Polyline';


const ControlledMap = ({ children, onClick, locations, polygons }) => {
    // const markerLocations = [/* whatever you need to get those... */];
    const map = useMap();
    
    const fitMapToToMarkers = useCallback(() => {
      if (!map) return;
  
      const bounds = new google.maps.LatLngBounds();

      for (const location of locations) {
          bounds.extend(location.location);
      }

      map.fitBounds(bounds);
    }, [map, locations]);
  
    // decide how you want to trigger `fitMapToMarkers`, via a button, when data changes, ...
    if(locations.length > 1) fitMapToToMarkers();
  
    return (
      <Map 
        mapId={'9ae767d743a90799'}
        style={{width: 'inherit', height: 'inherit'}}
        defaultCenter={{lat: locations[0].location.lat, lng: locations[0].location.lng}}
        defaultZoom={13}
        gestureHandling={'greedy'}
        onClick={onClick}
        disableDefaultUI
        disableDoubleClickZoom
      >
        {locations && <MapMarkers locations={locations} />}
        {polygons.length > 0 && polygons.map((pg, i) => {
            return (
               <Polyline 
                    key={i}
                    strokeWeight={10}
                    strokeColor={'#000000'}
                    encodedPath={pg}
                />
            );
        })}
      </Map>
    );
};

export default ControlledMap;