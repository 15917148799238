import React, {useCallback, useEffect, useRef, useState} from 'react';

import '../stylesheets/AudioControl.scss';
import {getAudioTranscript} from "../../utils/fetch/speechFetching";
import {wakeWords, stopRecordingDelay} from "../../utils/constants";
import {getSess, setSess} from "../../utils/dataFetching";
import { FaMicrophone } from "react-icons/fa";
import { useAuth } from '../../context/AuthContext';


let delayIntervalID = null;

const AudioControl = ({ onStateChange, audioState, isTalking, analyzer, audioRef, step }) => {
  const [isRecording, setIsRecording] = useState(false);
  const speechRecognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(true);
  const [isEnding, setIsEnding] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioStreamRef = useRef(null);

  const auth = useAuth();
  const { logout } = auth;

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      if (speechRecognitionRef.current) {
        if (document.hidden) {
          stopListening();
          stopRecording();
        } else {
          setIsListening(true);
          //BUGFIX TODO: need to make sure its not playing before starting
          speechRecognitionRef.current.start();
        }
      }
    });
  }, []);

  const stopListening = useCallback(() => {
    if (speechRecognitionRef.current) {
      console.log('STOP LISTENING');
      setIsListening(false);
      setSess('speechRecognitionRef', 'stopped');
      speechRecognitionRef.current.stop();
      console.log('STOPPED LISTENING');
      //speechRecognitionRef.current = null;
    }
  }, [speechRecognitionRef]);

  const startRecording = useCallback(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error('Media Devices API or getUserMedia not supported in this browser.');
      return;
    }

    try {
      //stopListening();

      if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
        console.log('starting audioStreamRef.current');
        const tracks = audioStreamRef.current.getAudioTracks();
        tracks[0].start();
      }

      console.log('starting mediaRecorderRef.current');
      setIsRecording(true);
      onStateChange('recording');
      mediaRecorderRef.current.start(1000);
    } catch (err) {
      console.error('Failed to start recording:', err);
    }
  }, [mediaRecorderRef, audioStreamRef, onStateChange]);

  const stopRecording = useCallback (() => {
    // Clear and null the delay interval so it doesn't keep firing
    clearRecordingDelayInterval();
    
    if (mediaRecorderRef.current && mediaRecorderRef?.current?.state === "recording") {
      // stopListening(); => This broke everything, btw

      setIsRecording(false);
      mediaRecorderRef.current.stop();

      if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
        console.log('stopping audioStreamRef.current');
        const tracks = audioStreamRef.current.getAudioTracks();
        tracks[0].stop();
      }
    }
  }, [mediaRecorderRef, audioStreamRef, stopListening]);

  const onSpeechRecognitionEnd = useCallback((e) => {
    if (!document.hidden) {
      console.log("speech recognition - onend",e,e.returnValue);

      console.log(isListening,isTalking);
      if (isListening && !isTalking) {
        console.log("restarting speech recognition service");
  
        if (getSess('speechRecognitionRef') === 'started') {
          speechRecognitionRef.current.start();
        }
        //speechRecognitionRef.current.start(); //restart recognition
      }
    }
  }, [speechRecognitionRef, isListening, isTalking]);

  const onSpeechRecognitionResult = useCallback((event) => {
    console.log("speech recognition - onresult");
    const last = event.results.length - 1;
    const text = event.results[last][0].transcript.trim().replace(/[^-a-z0-9\s]/ig,'');

    //reset the interval to stop recording audio
    if(delayIntervalID !== null) {
      //if it isn't null, fully remove it first
      clearInterval(delayIntervalID);
      delayIntervalID = null;

      delayIntervalID = setInterval(() => {
        // stop recording audio
        if(isRecording) {
          // console.log("delayInterval::stopRecording");
          stopRecording();
        }
      }, stopRecordingDelay);
    } else {
      delayIntervalID = setInterval(() => {
        // stop recording audio
        if(isRecording) {
          // console.log("delayInterval::stopRecording");
          stopRecording();
        }
      }, stopRecordingDelay);
    }

    // if we recognize the wake word
    console.log("Recognized text:",text);

    //if (text.toLowerCase().includes(wakeWord)) {
    if (wakeWords.some(str => new RegExp(`^${text}$`, "i").test(str)) && isRecording === false) {
      console.log("wake word recognized, startRecording");
      startRecording();
    }
  },[startRecording, stopRecording, isRecording]);

  // Reusable interval clearing method for the recording auto end delay
  const clearRecordingDelayInterval = () => {
    if(delayIntervalID !== null) {
      //if it isn't null, fully remove it
      clearInterval(delayIntervalID);
      delayIntervalID = null;
    }
  }

  // const toggleRecording = () => {
  //   console.log("toggleRecording");
  //   if (!isTalking && audioState !== 'processing') {
  //     if (isRecording) {
  //       // console.log("toggleRecording::stopRecording");
  //       stopRecording();
  //     } else {
  //       // console.log("toggleRecording::startRecording");
  //       startRecording();
  //     }
  //   }
  // };

  const toggleRecording = () => {
    console.log("toggleRecording");

    console.log(`audioState: ${audioState}`);
    console.log(`isTalking: ${isTalking}`);
    console.log(`isRecording: ${isRecording}`);

    
    if (audioState !== 'processing') {
      if (isTalking) {
        if (audioRef && audioRef.current) {
          audioRef.current.pause();
        }
      } else {
        if (isRecording) {
          // console.log("toggleRecording::stopRecording");
          stopRecording();
        } else {
          // console.log("toggleRecording::startRecording");
          startRecording();
        }
      }
    }
  };

  useEffect(() => {
    console.log('ON', isListening);
    let micStream;

    function initMediaRecorder() {
      if (audioStreamRef?.current) {
        console.log('STREAM FOUND, RETURN');
        return;
      }

      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        console.error('Media Devices API or getUserMedia not supported in this browser.');
        return;
      }

      try {
        if (!audioStreamRef?.current) {
          console.log('NO STREAM', audioStreamRef);
          audioStreamRef.current = navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
            if (!mediaRecorderRef?.current) {
              console.log('OBTAIN RECORDER', mediaRecorderRef);
              mediaRecorderRef.current = new MediaRecorder(stream);

              if (analyzer) {
                micStream = analyzer.audioCtx.createMediaStreamSource(stream);
                analyzer.connectInput(micStream);
                analyzer.volume = 0;
              }

              console.log('INIT RECORDER');

              let recordedChunks = [];

              mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                  recordedChunks.push(event.data);
                }
              };

              mediaRecorderRef.current.onstop = async () => {
                // console.log("mediaRecorder::onstop");
                onStateChange('processing');
                const audioBlob = new Blob(recordedChunks, {type: "audio/webm;codecs=opus"});

                recordedChunks = [];
                try {
                  const transcriptRes = await getAudioTranscript(audioBlob, logout);

                  console.log('Audio recording stopped and processed.', transcriptRes);

                  onStateChange('ready', transcriptRes);

                } catch (e) {
                  console.log('ERROR RECORDER ONSTOP',e);
                }
              };
            }
            else {
              console.log('RECORDER EXISTS', mediaRecorderRef);
            }

          })
        }
        else {
          console.log('STREAM EXISTS', audioStreamRef);
        }

      } catch (e) {

      }
    }

    function initSpeechRecognitionDISABLED() {
      if (speechRecognitionRef?.current) {
        // Refresh the onend handler so isListening/Talking is updated within it
        speechRecognitionRef.current.onend = onSpeechRecognitionEnd;
        speechRecognitionRef.current.onresult = onSpeechRecognitionResult;
      }

      if (!speechRecognitionRef?.current && isListening) {
        if('speechrecognition' in window) {
          console.log("in streamMic speechrecognition in window");
          speechRecognitionRef.current = new window.speechrecognition();
        } else if('webkitSpeechRecognition' in window) {
          console.log("in streamMic webkitSpeechRecognition in window");
          speechRecognitionRef.current = new window.webkitSpeechRecognition();
        } else {
          console.log('SpeechRecognition web API not found');
        }

        if (speechRecognitionRef?.current) {
          const audiostart = () => {
            console.log("speech recognition - onaudiostart");
            //onStateChange('listening');
          },
          speechstart = () => {
            console.log("speech recognition - onspeechstart");
            // Remove delayed stop recording interval while speech is being recognized
            clearRecordingDelayInterval();
          },
          speechend = () => {
            console.log("speech recognition - onspeechend");
          },
          error = (e) => {
            console.log('speech recognition - ONERROR',e);
          };

          console.log('INIT speechRecognitionRef');
          speechRecognitionRef.current.lang = 'en-US';
          // These params didn't seem to help
          // speechRecognitionRef.current.continuous = true;
          // speechRecognitionRef.current.interimResults = true;
          onStateChange('listening');
          speechRecognitionRef.current.start();
          setSess('speechRecognitionRef', 'started');

          // Fires when speech recognition service has begun listening to incoming audio
          speechRecognitionRef.current.onaudiostart = audiostart;
          // Fires when human speech is recognized (person starts talking)
          speechRecognitionRef.current.onspeechstart = speechstart
          // Fires when it stops recognizing human speech (person stops talking)
          speechRecognitionRef.current.onspeechend = speechend;
          // Fires when the speech recognition service returns a result — a word or phrase has been positively recognized and this has been communicated back to the app. Also available via the onresult property.
          speechRecognitionRef.current.onresult = onSpeechRecognitionResult;
          speechRecognitionRef.current.onerror = error;
          speechRecognitionRef.current.onend = onSpeechRecognitionEnd;
        } else {
          console.log('SpeechRecognition web API not found');
        }
      }
    }

    console.log('INIT AUDIO CONTROL');

    initMediaRecorder();
    // initSpeechRecognition();

     return () => {
    // This fires too often, and would needlessly stop listeners (with or without dependencies provided)
        console.log('OFF');
//   if (speechRecognitionRef.current) {
    //     console.log('stopping speechRecognitionRef');
    //     speechRecognitionRef.current.stop();
    //     //speechRecognitionRef.current = null;
    //   }
    //   if (audioStreamRef.current && audioStreamRef.current.getAudioTracks) {
    //     const tracks = audioStreamRef.current.getAudioTracks();
    //     console.log('stopping audioStreamRef.current');
    //
    //     tracks[0].stop();
    //     //audioStreamRef.current = null;
    //   }
     };
  }, [onStateChange, startRecording, stopRecording, speechRecognitionRef, onSpeechRecognitionEnd, onSpeechRecognitionResult, isListening, isRecording]);

  useEffect(() => {
    if (!document.hidden) {
      if (speechRecognitionRef.current && !isListening && !isTalking && audioState === 'listening') {
        console.log('STOPPED TALKING, START LISTENING',audioState,speechRecognitionRef.current)
        setIsListening(true);
        onStateChange('listening');
        if (getSess('speechRecognitionRef') === 'stopped') {
          speechRecognitionRef.current.start();
          setSess('speechRecognitionRef', 'started');
        }
      }
      if (isListening && isTalking) {
        console.log("isListening & isTalking is true, STOP LISTENING");
        stopListening();
      }
    }
  }, [speechRecognitionRef, onStateChange, isListening, isTalking, audioState, stopListening]);

  return (
      <button className="ptt" onClick={toggleRecording}><FaMicrophone color={isRecording ? 'red' : 'white'} size={24} /></button>
  );
};

export default AudioControl;
