import React, {useCallback, useEffect, useState} from 'react';
import './App.scss';

import {Route, Routes} from "react-router-dom";
import {getLocal, setLocal} from "./utils/dataFetching";
import {useAuth} from "./context/AuthContext";

import EnterCode from "./pages/EnterCode";
import Transit from "./pages/Transit";

let queryParams = new URLSearchParams(window.location.search);
let ccode = queryParams.get('key');// || getLocal('ccode');

window.onpopstate = () => {
  window.location.reload();
};

function App() {
  document.title = 'Transit AI';

  const { isAuthenticated, login, logout } = useAuth();
  //const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  // const navigate = useCallback ((p) => {
  //   // This currently fixes the issues encountered by switching between modes
  //   //    the speech recognition service doesn't get unloaded (commented out bc it was causing other issues)
  //   window.location = p;
  // },[]);
  const attemptLogin = useCallback(async (ccode) => {
    //console.log(submitted);
    if (!submitted) {
      setSubmitted(true);
      console.log("");
      console.log('attempting Login', ccode);

      try {
        let loginResult = await login(ccode);
        console.log(loginResult);

        if (loginResult === 'wait') {
          //window.location.reload();
          return;
        }
        if (loginResult.status === 200) {
          window.location = "/";
          // queryParams.delete("key");

          // window.location.reload();
          //handleLoginSuccess(ccode);
        } else if (loginResult.status === 'error') {
          console.log('Invalid key');
        } else {
          console.log(loginResult && !loginResult.success ? loginResult.message : "An unexpected error occurred.");
        }
      } catch (e) {
        console.log(e.message);
      } finally {
        //setTimeout(() => setSubmitted(false),2000);
      }
    }
  }, [login, submitted]);

  useEffect(() => {

    const attempt = async () => {
      console.log('effect',ccode,isAuthenticated, submitted);

      if (ccode && !isAuthenticated && !submitted) {
        console.log(isAuthenticated, submitted);
        await attemptLogin(ccode);
      }
    }

    attempt();
  }, [ attemptLogin, submitted, isAuthenticated]);



  useEffect(() => {
    async function fetchToken() {
      try {
        const token = getLocal("auth_token");
        if (!token && isAuthenticated) logout();
      } catch (error) {}
    }

    fetchToken();
  }, []);

  return (
    <div className={`col-12 App`}> 
      <Routes>
        {isAuthenticated && (
          <Route path="/" element={<Transit />} />
        ) }
        
        {!isAuthenticated && (
          <Route path="/" element={<EnterCode />} />
        )}
      </Routes>
      {/* <audio ref={audioRef} autoPlay src='/speech.mp3' crossOrigin="anonymous" /> */}
    </div>
  );
}

export default App;
