// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,aAAY;EACZ,uBAAuB,EAAA","sourcesContent":[".App {\n  background-color: white;\n  height: 100%;\n  display:flex;\n  justify-content: center;\n\n  // &.knowledge-assistant,\n  // &.waveform {\n  //   background-image: var(--knowledge-base-landscape-bg-image);\n  //   background-size: 100% 100%;\n  //   background-repeat: no-repeat;\n  // }\n\n  // &.role-play,\n  // &.objection,\n  // &.objection-advance {\n  //   align-items: center;\n  //   background-image: var(--default-bg-image);\n  //   background-repeat: no-repeat;\n  //   background-size: cover;\n  //   background-position: top center;\n  //   background-color: #343233;\n  // }\n\n  // &.role-play,\n  // &.audio-transcript,\n  // &.objection,\n  // &.objection-advance {\n  //   background-image: var(--landscape-bg-image);\n  // }\n\n  // &.knowledge-assistant,\n  // &.audio-transcript,\n  // &.waveform {\n  //   &::after {\n  //     content: 'Click to enable audio playback';\n  //     position: fixed;\n  //     bottom: 0;\n  //     padding: 1rem 1.5rem;\n  //     background-color: rgba(0,0,0,0.25);\n  //     color: white;\n  //   }\n  //   &.audio-enabled::after {\n  //     content: none;\n  //   }\n  // }\n\n}\n\n@media screen and (max-width: 1180px) and (orientation: landscape) {\n\n}\n\n@media screen and (orientation: portrait) {\n\n}\n\n@media screen and (orientation: portrait) {\n\n}\n\n@media screen and (max-width: 767px) {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
