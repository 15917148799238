import {
    AdvancedMarker,
    MapCameraChangedEvent,
    Pin
  } from '@vis.gl/react-google-maps';

  const MapMarkers = ({ locations }) => {
    return (
      <>
        {locations.map( (location) => (
          <AdvancedMarker
            content={location.key}
            key={location.key}
            position={location.location}>
            <Pin background={location.colors.bg} glyphColor={location.colors.glyph} borderColor={location.colors.border} />
          </AdvancedMarker>
        ))}
      </>
    );
  };

  export default MapMarkers;